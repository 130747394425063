import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from '@mui/material';
import axios from 'axios';

function Settings() {
  const [vehiclePrices, setVehiclePrices] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    // Fetch vehicle prices and capacities
    axios.get('https://kride.kirupam.shop/api/admin/vehicle_prices.php')
      .then(response => setVehiclePrices(response.data))
      .catch(error => console.error('Error fetching vehicle prices:', error));
  }, []);

  const handleEditClick = (vehicle) => {
    setEditingId(vehicle.id);
    setEditedData(vehicle);
  };

  const handleSaveClick = () => {
    axios.put('https://kride.kirupam.shop/api/admin/vehicle_prices.php', editedData)
      .then(response => {
        if (response.data.success) {
          setVehiclePrices(prevPrices =>
            prevPrices.map(vehicle =>
              vehicle.id === editedData.id ? editedData : vehicle
            )
          );
          setEditingId(null);
        } else {
          console.error('Error updating vehicle price:', response.data.message);
        }
      })
      .catch(error => console.error('Error updating vehicle price:', error));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Vehicle Prices and Capacities</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Vehicle Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Capacity</TableCell>
              <TableCell>Minimum</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehiclePrices.map(vehicle => (
              <TableRow key={vehicle.id}>
                <TableCell>
                  {vehicle.vehicle_type}
                </TableCell>
                <TableCell>
                  {editingId === vehicle.id ? (
                    <TextField
                      name="price"
                      type="number"
                      value={editedData.price}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    vehicle.price
                  )}
                </TableCell>
                <TableCell>
                  {editingId === vehicle.id ? (
                    <TextField
                      name="capacity"
                      type="number"
                      value={editedData.capacity}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    vehicle.capacity
                  )}
                </TableCell>
                <TableCell>
                  {editingId === vehicle.id ? (
                    <TextField
                      name="minimum"
                      type="number"
                      value={editedData.minimum}
                      onChange={handleChange}
                      fullWidth
                    />
                  ) : (
                    vehicle.minimum
                  )}
                </TableCell>
                <TableCell>
                  {editingId === vehicle.id ? (
                    <Button variant="contained" color="primary" onClick={handleSaveClick}>
                      Save
                    </Button>
                  ) : (
                    <Button variant="contained" color="secondary" onClick={() => handleEditClick(vehicle)}>
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Settings;
