import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Modal,
} from '@mui/material';
import axios from 'axios';

function SalaryManagement() {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [earnings, setEarnings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = () => {
    setLoading(true);
    axios.get('https://kride.kirupam.shop/api/admin/earnings_management.php?action=fetch_drivers')
      .then(response => {
        setDrivers(response.data.drivers || []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching drivers:', error);
        setLoading(false);
      });
  };

  const handleViewClick = (driver) => {
    setSelectedDriver(driver);
    fetchEarnings(driver.id);
    setOpen(true);
  };

  const fetchEarnings = (driverId) => {
    setLoading(true);
    axios.get(`https://kride.kirupam.shop/api/admin/earnings_management.php?action=fetch_earnings&driver_id=${driverId}`)
      .then(response => {
        setEarnings(response.data.earnings || []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching earnings:', error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDriver(null);
    setEarnings([]);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Salary Management</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Driver ID</TableCell>
                <TableCell>Driver Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers.map(driver => (
                <TableRow key={driver.id}>
                  <TableCell>{driver.id}</TableCell>
                  <TableCell>{driver.driver_name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleViewClick(driver)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="driver-earnings-title"
        aria-describedby="driver-earnings-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" id="driver-earnings-title" gutterBottom>
            Earnings for {selectedDriver?.driver_name}
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Trip ID</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Driver Earnings</TableCell>
                    <TableCell>Admin Earnings</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {earnings.map(earning => (
                    <TableRow key={earning.trip_id}>
                      <TableCell>{earning.trip_id}</TableCell>
                      <TableCell>{earning.total_cost}</TableCell>
                      <TableCell>{earning.payment_method}</TableCell>
                      <TableCell>{earning.status}</TableCell>
                      <TableCell>{(earning.total_cost * 0.9).toFixed(2)}</TableCell>
                      <TableCell>{(earning.total_cost * 0.1).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
};

export default SalaryManagement;
