import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Users from './pages/Users';
import Drivers from './pages/Drivers';
import Settings from './pages/Settings';
import Bookings from './pages/Bookings';
import Earnings from './pages/Earnings';
import AdminUsers from './pages/AdminUsers';
import BookingDetails from './pages/BookingDetails';
import Login from './pages/Login';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Box, CssBaseline, Button } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [playNotification, setPlayNotification] = useState(false);
  const notificationSoundRef = useRef(null);

  const drawerWidth = 240;

  useEffect(() => {
    if (playNotification) {
      // Fetch new booking data every minute
      const interval = setInterval(() => {
        axios.get('https://kride.kirupam.shop/api/admin/new_bookings.php')
          .then(response => {
            const newBookings = response.data;

            // Check if there are new bookings with "Pending" status
            const hasPending = newBookings.some(booking => booking.status === 'pending');
            if (hasPending) {
              // Play notification sound if there are pending bookings
              if (notificationSoundRef.current) {
                notificationSoundRef.current.play().catch(error => console.error('Audio play error:', error));
              }
            }
            setBookings(newBookings);
          })
          .catch(error => console.error('Error fetching new bookings:', error));
      }, 6000); // Check every 60 seconds (1 minute)

      return () => clearInterval(interval);
    }
  }, [playNotification, bookings.length]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const enableNotifications = () => {
    setPlayNotification(true);
  };

  return (
    <Router>
      <CssBaseline />
      <audio ref={notificationSoundRef} src="/assets/sounds/success.mp3" preload="auto" />
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <Sidebar
          mobileOpen={mobileOpen}
          onDrawerToggle={handleDrawerToggle}
          drawerWidth={drawerWidth}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 10,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Routes>
            <Route path="/" element={authenticated ? <Navigate to="/home" /> : <Login setAuthenticated={setAuthenticated} />} />
            <Route path="/home" element={authenticated ? <Home /> : <Navigate to="/" />} />
            <Route path="/users" element={authenticated ? <Users /> : <Navigate to="/" />} />
            <Route path="/drivers" element={authenticated ? <Drivers /> : <Navigate to="/" />} />
            <Route path="/settings" element={authenticated ? <Settings /> : <Navigate to="/" />} />
            <Route path="/earnings" element={authenticated ? <Earnings /> : <Navigate to="/" />} />
            <Route path="/bookings" element={authenticated ? <Bookings /> : <Navigate to="/" />} />
            <Route path="/adminusers" element={authenticated ? <AdminUsers /> : <Navigate to="/" />} />
            <Route path="/booking/:id" element={authenticated ? <BookingDetails /> : <Navigate to="/" />} />
          </Routes>

          <Button variant="contained" color="primary" onClick={enableNotifications}>
            Enable Notifications
          </Button>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
