import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardActions,
  Typography,
  Avatar,
} from '@mui/material';
import { Add, Edit, Delete, ViewList, ViewModule } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

function Drivers() {
  const { enqueueSnackbar } = useSnackbar();
  const [drivers, setDrivers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [editingDriver, setEditingDriver] = useState(null);
  const [selectedDriverDocuments, setSelectedDriverDocuments] = useState({});
  const [newDriver, setNewDriver] = useState({
    driver_name: '',
    phone: '',
    vehicle_number: '',
    vehicle_type: '',
    vehicle_model: '',
    driver_address: '',
    driver_email: '',
    password: '',
    approval: 0,
    license_image: null,
    nic_image_front: null,
    nic_image_back: null,
    vehicle_book_image: null,
    vehicle_license_image: null,
    profile_picture: null,
  });
  const [view, setView] = useState('table'); // view toggle: 'table' or 'card'

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = () => {
    axios.get('https://kride.kirupam.shop/api/admin/get_drivers.php?action=fetch')
      .then(response => setDrivers(response.data))
      .catch(error => {
        console.error('Error fetching drivers:', error);
        enqueueSnackbar('Error fetching drivers.', { variant: 'error' });
      });
  };

  const handleDialogOpen = (driver = null) => {
    setEditingDriver(driver);
    setNewDriver(driver || {
      driver_name: '',
      phone: '',
      vehicle_number: '',
      vehicle_type: '',
      vehicle_model: '',
      driver_address: '',
      driver_email: '',
      password: '',
      approval: 0,
      license_image: null,
      nic_image_front: null,
      nic_image_back: null,
      vehicle_book_image: null,
      vehicle_license_image: null,
      profile_picture: null,
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditingDriver(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDriver(prev => ({ ...prev, [name]: value }));
  };

  const handleApprovalChange = (e) => {
    setNewDriver(prev => ({ ...prev, approval: parseInt(e.target.value) }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewDriver(prev => ({ ...prev, [name]: files[0] }));
  };

  const handleSave = () => {
    const formData = new FormData();
    Object.keys(newDriver).forEach(key => {
      if (newDriver[key] !== null) {
        formData.append(key, newDriver[key]);
      }
    });

    const url = editingDriver
      ? 'https://kride.kirupam.shop/api/admin/get_drivers.php?action=update'
      : 'https://kride.kirupam.shop/api/admin/get_drivers.php?action=create';

    axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(response => {
        fetchDrivers();
        enqueueSnackbar(`Driver ${editingDriver ? 'updated' : 'added'} successfully.`, { variant: 'success' });
        handleDialogClose();
      })
      .catch(error => {
        console.error(`Error ${editingDriver ? 'updating' : 'adding'} driver:`, error);
        enqueueSnackbar(`Error ${editingDriver ? 'updating' : 'adding'} driver.`, { variant: 'error' });
      });
  };

  const handleDelete = (id) => {
    axios.get(`https://kride.kirupam.shop/api/admin/get_drivers.php?action=delete&id=${id}`)
      .then(response => {
        fetchDrivers();
        enqueueSnackbar('Driver deleted successfully.', { variant: 'success' });
      })
      .catch(error => {
        console.error('Error deleting driver:', error);
        enqueueSnackbar('Error deleting driver.', { variant: 'error' });
      });
  };

  const handleDocumentsClick = (driver) => {
    setSelectedDriverDocuments({
      license_image: driver.license_image,
      nic_image_front: driver.nic_image_front,
      nic_image_back: driver.nic_image_back,
      vehicle_book_image: driver.vehicle_book_image,
      vehicle_license_image: driver.vehicle_license_image,
    });
    setOpenDocumentDialog(true);
  };

  const handleDocumentDialogClose = () => {
    setOpenDocumentDialog(false);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Drivers Management</Typography>
        <Box>
          <IconButton onClick={() => setView('table')}>
            <ViewList />
          </IconButton>
          <IconButton onClick={() => setView('card')}>
            <ViewModule />
          </IconButton>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleDialogOpen()}
      >
        Add Driver
      </Button>

      {view === 'table' ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Driver Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Vehicle Number</TableCell>
                <TableCell>Vehicle Type</TableCell>
                <TableCell>Vehicle Model</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Approval</TableCell>
                <TableCell>Documents</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {drivers.map(driver => (
                <TableRow key={driver.id}>
                  <TableCell>{driver.id}</TableCell>
                  <TableCell>{driver.driver_name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell>{driver.vehicle_number}</TableCell>
                  <TableCell>{driver.vehicle_type}</TableCell>
                  <TableCell>{driver.vehicle_model}</TableCell>
                  <TableCell>{driver.driver_address}</TableCell>
                  <TableCell>{driver.driver_email}</TableCell>
                  <TableCell>{driver.approval === '1' ? 'Approved' : 'Pending'}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDocumentsClick(driver)}
                    >
                      View/Edit Documents
                    </Button>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDialogOpen(driver)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(driver.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex" flexWrap="wrap">
          {drivers.map(driver => (
            <Card key={driver.id} sx={{ maxWidth: 345, margin: 2 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {driver.driver_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Phone: {driver.phone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Vehicle: {driver.vehicle_number} ({driver.vehicle_type})
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Address: {driver.driver_address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Email: {driver.driver_email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Status: {driver.approval === '1' ? 'Approved' : 'Pending'}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleDocumentsClick(driver)}>
                  Documents
                </Button>
                <IconButton onClick={() => handleDialogOpen(driver)}>
                  <Edit />
                </IconButton>
                <IconButton color="error" onClick={() => handleDelete(driver.id)}>
                  <Delete />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{editingDriver ? 'Edit Driver' : 'Add Driver'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Driver Name"
            name="driver_name"
            value={newDriver.driver_name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={newDriver.phone}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Vehicle Number"
            name="vehicle_number"
            value={newDriver.vehicle_number}
            onChange={handleChange}
            fullWidth
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Vehicle Type</InputLabel>
            <Select
              label="Vehicle Type"
              name="vehicle_type"
              value={newDriver.vehicle_type}
              onChange={handleChange}
            >
              <MenuItem value="Three Wheeler">Three Wheeler</MenuItem>
              <MenuItem value="Mini Car">Mini Car</MenuItem>
              <MenuItem value="Bajaj Qute">Bajaj Qute</MenuItem>
              <MenuItem value="Car">Car</MenuItem>
              <MenuItem value="Flexible Car">Flexible Car</MenuItem>
              <MenuItem value="Special Car">Special Car</MenuItem>
              <MenuItem value="Mini Van">Mini Van</MenuItem>
              <MenuItem value="KDH">KDH</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Vehicle Model"
            name="vehicle_model"
            value={newDriver.vehicle_model}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address"
            name="driver_address"
            value={newDriver.driver_address}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Email"
            name="driver_email"
            value={newDriver.driver_email}
            onChange={handleChange}
            fullWidth
          />
          {!editingDriver && (
            <TextField
              margin="dense"
              label="Password"
              name="password"
              type="password"
              value={newDriver.password}
              onChange={handleChange}
              fullWidth
            />
          )}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Approval</InputLabel>
            <Select
              value={newDriver.approval}
              onChange={handleApprovalChange}
            >
              <MenuItem value={1}>Approved</MenuItem>
              <MenuItem value={0}>Pending</MenuItem>
            </Select>
          </FormControl>
          <input
            type="file"
            name="license_image"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label>License Image</label>
          <input
            type="file"
            name="nic_image_front"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label>NIC Image Front</label>
          <input
            type="file"
            name="nic_image_back"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label>NIC Image Back</label>
          <input
            type="file"
            name="vehicle_book_image"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label>Vehicle Book Image</label>
          <input
            type="file"
            name="vehicle_license_image"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label>Vehicle License Image</label>
          <input
            type="file"
            name="profile_picture"
            accept="image/*"
            onChange={handleFileChange}
          />
          <label>Profile Picture</label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDocumentDialog} onClose={handleDocumentDialogClose}>
        <DialogTitle>View/Edit Documents</DialogTitle>
        <DialogContent>
          <List>
            {['license_image', 'nic_image_front', 'nic_image_back', 'vehicle_book_image', 'vehicle_license_image'].map((field, index) => (
              selectedDriverDocuments[field] ? (
                <ListItem key={index}>
                  <ListItemText primary={field.replace(/_/g, ' ')} />
                  <Button variant="outlined" href={`https://kride.kirupam.shop/api/admin/uploads/${selectedDriverDocuments[field]}`} target="_blank">
                    View
                  </Button>
                </ListItem>
              ) : (
                <ListItem key={index}>
                  <ListItemText primary={field.replace(/_/g, ' ')} secondary="Document required" />
                </ListItem>
              )
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDocumentDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Drivers;
